<template>
  <div style="padding-bottom: 100px">
    <top-fixed></top-fixed>
    <div
      style="
        border-radius: 20px;
        overflow: hidden;
        margin-top: 0.4rem;
        background-size: 7.5rem 1rem;
      "
      :class="
        selectIndex == 1 ? 'select1' : selectIndex == 2 ? 'select2' : 'select3'
      "
    >
      <div
        style="
          display: flex;

          height: 1rem;
          justify-content: space-around;
          align-items: center;
        "
      >
        <div
          style="flex: 1; text-align: center; height: 1rem; line-height: 1rem"
          @click="changeIndex(1)"
        >
          {{ $t("hj9") }}
        </div>
        <div
          style="flex: 1; text-align: center; height: 1rem; line-height: 1rem"
          @click="changeIndex(2)"
        >
          {{ $t("hj10") }}
        </div>
        <div
          style="
            flex: 1;
            text-align: center;
            height: 0.7rem;
            line-height: 0.7rem;
          "
          @click="changeIndex(3)"
        >
          {{ $t("hj11") }}
        </div>
      </div>
      <div v-if="selectIndex == 3">
        <div
          v-for="(item, index) in txData"
          :key="index"
          style="margin-top: 0.4rem; padding: 0 0.25rem"
        >
          <div
            class="tixian_bg"
            style="
              height: 1.6rem;
              padding: 0 0.3rem;
              background-size: 7rem auto;

              display: flex;
            "
          >
            <div style="flex: 1">
              <div
                style="
                  color: #ff381d;
                  font-size: 18px;
                  font-weight: bold;
                  margin-top: 0.3rem;
                  font-style: italic;
                "
              >
                {{ item.status_name }}
              </div>
              <div style="margin-top: 0.2rem; font-size: 12px; color: #978f8f">
                {{ item.created_at }}
              </div>
            </div>
            <div style="flex: 1; margin-left: 0.2rem">
              <div style="margin-top: 0.35rem; color: #978f8f; font-size: 16px">
                {{ $t("hj13") }}:
              </div>
              <div
                style="
                  margin-top: 0.1rem;
                  font-size: 18px;
                  font-style: italic;
                  color: #ff381d;
                  font-weight: bold;
                "
              >
                {{ item.usdt_amount }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="selectIndex == 2">
      <div
        v-for="(item, index) in rewardData"
        :key="index"
        style="margin-top: 0.4rem; padding: 0 0.25rem"
      >
        <div
          class="huodong_bg"
          style="height: 2.75rem; padding: 0 0.3rem; background-size: 7rem auto"
        >
          <div style="font-weight: bold; font-size: 17px; padding-top: 0.3rem">
            ETH{{ $t("hj14") }}:
          </div>
          <div
            style="
              font-weight: bold;
              color: #ff381d;
              font-size: 17px;
              margin-top: 0.1rem;
              font-style: italic;
            "
          >
            {{ item.amount }}
          </div>
          <div style="color: #978f8f; font-size: 12px; margin-top: 0.1rem">
            {{ item.created_at }}
          </div>
          <div style="font-size: 16px; margin-top: 0.4rem">
            USDT{{ $t("hj15") }}:
          </div>
          <div style="display: flex; align-items: center; margin-top: 0.1rem">
            <div
              style="
                font-weight: bold;
                color: #ff381d;
                font-size: 17px;
                font-style: italic;
                flex: 1;
              "
            >
              {{ item.usdt_amount }}
            </div>
            <div style="font-size: 12px; color: #978f8f; flex: 1">
              {{ $t("hj16") }}:{{ item.rate }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="selectIndex == 1">
      <div
        v-for="(item, index) in rewardData"
        :key="index"
        style="margin-top: 0.4rem; padding: 0 0.25rem"
      >
        <div
          class="huodong_bg"
          style="height: 2.75rem; padding: 0 0.3rem; background-size: 7rem auto"
        >
          <div style="font-weight: bold; font-size: 17px; padding-top: 0.3rem">
            ETH{{ $t("hj14") }}:
          </div>
          <div
            style="
              font-weight: bold;
              color: #ff381d;
              font-size: 17px;
              margin-top: 0.1rem;
              font-style: italic;
            "
          >
            {{ item.amount }}
          </div>
          <div style="color: #978f8f; font-size: 12px; margin-top: 0.1rem">
            {{ item.created_at }}
          </div>
          <div style="font-size: 16px; margin-top: 0.4rem">
            USDT{{ $t("hj15") }}:
          </div>
          <div style="display: flex; align-items: center; margin-top: 0.1rem">
            <div
              style="
                font-weight: bold;
                color: #ff381d;
                font-size: 17px;
                font-style: italic;
                flex: 1;
              "
            >
              {{ item.usdt_amount }}
            </div>
            <div style="font-size: 12px; color: #978f8f; flex: 1">
              {{ $t("hj16") }}:{{ item.rate }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <bottom-fixed></bottom-fixed>
  </div>
</template>


<script>
import BottomFixed from "../components/BottomFixed.vue";
import TopFixed from "../components/TopFixed.vue";
import { reward, withdraw_logs } from '../http/api'
export default {
  name: "AccountView",
  data () {
    return {
      selectIndex: 1,
      rewardData: '',
      txData: ""
    };
  },
  components: { BottomFixed, TopFixed },
  mounted () {
    this.dataInit()
    this.changeIndex(1)
  },
  methods: {
    changeIndex (num) {
      this.rewardData = ''
      this.selectIndex = num
      switch (num) {
        case 1:
          reward({ type: this.selectIndex }).then((res) => {
            console.log(res.data)
            this.rewardData = res.data
          })
          break
        case 2:
          reward({ type: this.selectIndex }).then((res) => {
            console.log(res.data)
            this.rewardData = res.data
          })
          break
        case 3:
          withdraw_logs().then((res) => {
            console.log(res.data)
            this.txData = res.data
          })
          break
      }
    },
    dataInit () {
      reward({ type: this.selectIndex }).then((res) => {
        console.log(res.data)
      })
    }
  },

};
</script>
<style scoped>
.account {
  background-image: url("../assets/img/5-提现记录.png");
  background-size: 7.5rem;

  width: 7.5rem;
  height: 15rem;
  background-position-y: -51px;
}

input {
  background: none;
  outline: none;
  border: none;
}

.huodong_bg {
  background-image: url("../assets/img/huodong_bg.png");
}

.tixian_bg {
  background-image: url("../assets/img/tixian_bg.png");
}

.select1 {
  background-image: url("../assets/img/bg_01.jpg");
}
.select2 {
  background-image: url("../assets/img/bg_02.jpg");
}
.select3 {
  background-image: url("../assets/img/bg_03.jpg");
}
</style>